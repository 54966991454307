<template>
  <Layout>
    <div class="createClass">
      <div class="container">
        <modal-upload :data="data"></modal-upload>
        <modal-categoria></modal-categoria>
        <b-row class="spaceWizzard">
          <b-col cols="12" md="3" class="pt-5" data-anima="top">
            <div class="navigate">
              <div class="title d-flex flex-column">
                {{ AllCourses.title }}
                <button
                  id="viewModuleLesson"
                  class="viewModuleLesson d-flex mt-2"
                  @click="viewCourse()"
                >
                  Ver Curso
                  <IconSax class="ml-2" name="redo" type="outline" size="1rem" color="#c4c4c4" />
                </button>
              </div>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/' + cursoAtual"
                class="removeLink"
              >
                <div class="spaceNav">
                  <IconSax name="monitor" />
                  <p class="m-0 text ml-3">Dados do Curso</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto"  />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/' + cursoAtual + '/module'"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="book-square" />
                  <p class="m-0 text ml-3">Módulos e Aulas</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/class/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="profile-2user" />
                  <p class="m-0 text ml-3">Turmas</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/create-certificates/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="medal-star" />
                  <p class="m-0 text ml-3">Certificado</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <!-- <div class="lineDiv"></div>
            <router-link
              :to="'/config_curso/emails/' + cursoAtual + ''"
              class="removeLink"
              :class="{ disableLink: existParans }"
            >
              <div class="spaceNav">
                <div class="text"><svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V13C0 13.7956 0.316071 14.5587 0.87868 15.1213C1.44129 15.6839 2.20435 16 3 16H17C17.7956 16 18.5587 15.6839 19.1213 15.1213C19.6839 14.5587 20 13.7956 20 13V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3L10 7.88L2 3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2ZM18 13C18 13.2652 17.8946 13.5196 17.7071 13.7071C17.5196 13.8946 17.2652 14 17 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V5.28L9.48 9.85C9.63202 9.93777 9.80446 9.98397 9.98 9.98397C10.1555 9.98397 10.328 9.93777 10.48 9.85L18 5.28V13Z" fill="#333333"/>
</svg>
  E-mails</div>
                <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
              </div>
            </router-link> -->
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/vendas/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="bag-2" />
                  <p class="m-0 text ml-3">Vendas</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <div class="lineDiv"></div>

              <div class="spaceBtn">
                <toggle-button
                  :sync="true"
                  :labels="{ checked: 'Ativo', unchecked: 'Inativo' }"
                  v-model="myDataVariable"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="9" class="pt-5" data-anima="top">
            <div class="containerWizzard">
              <div v-if="toAddClass === false" class="title d-flex justify-content-center w-100">
                Minhas Turmas
                <IconSax name="profile-2user" size="1.7rem" class="ml-2" />
              </div>
              <div v-else class="title">
                {{ textAction }}
                <svg
                  width="26"
                  height="22"
                  viewBox="0 0 26 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 20V18C17 16.9391 16.5786 15.9217 15.8284 15.1716C15.0783 14.4214 14.0609 14 13 14H6C4.93913 14 3.92172 14.4214 3.17157 15.1716C2.42143 15.9217 2 16.9391 2 18V20M21 7V13M24 10H18M13.5 6C13.5 8.20914 11.7091 10 9.5 10C7.29086 10 5.5 8.20914 5.5 6C5.5 3.79086 7.29086 2 9.5 2C11.7091 2 13.5 3.79086 13.5 6Z"
                    stroke="#333333"
                    stroke-width="2.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div class="containerWizzardInputs animationOpacity2">
              <b-table
                :fields="fieldsx"
                show-empty
                small
                stacked="md"
                :items="AllClass"
                :tbody-tr-class="rowClass"
                :filter-included-fields="filterOn"
                v-if="toAddClass === false"
              >
                <template #empty>
                  <p class="title text-center-empty">{{ emptyText }}</p>
                </template>
                <template #emptyfiltered>
                  <p class="title text-center-empty">{{ emptyText }}</p>
                </template>

                <template #cell(title)="row">
                  <div @click="howToEdit(row.item.id)" class="text pointer">
                    {{ row.item.title }}
                  </div>
                </template>
                <template #cell(students_count)="row">
                  <div @click="howToEdit(row.item.id)" class="text pointer">
                    {{ row.item.students_count }} Alunos
                  </div>
                </template>
                <template #cell(updated_at)="row">
                  <div
                    class="text pointer flexItem"
                    style="white-space: nowrap"
                  >
                    {{ row.item.updated_at | moment('DD/MM/YYYY HH:mm') }}
                  </div>
                </template>
                <template #cell(created_at)="row">
                  <div class="actionData">
                    <div class="editar" @click="howToEdit(row.item)">
                      Editar
                    </div>
                    <div class="deletar" @click="deleteClass(row.item.id)">
                      Deletar
                    </div>
                  </div>
                </template>
              </b-table>
              <div v-else>
                <div class="spaceInputs">
                  <b-form-group label="Nome da Turma" label-for="name-aula">
                    <b-form-input
                      v-model="nameClass"
                      placeholder="Nome da Turma"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <span v-if="toEditId !== ''">
                  <div class="spaceInputs">
                    <b-form-group
                      label="Liberação da Turma"
                      label-for="name-aula"
                    >
                      <button @click="openModalTurma" class="btnListModule">
                        Configurar Turma
                      </button>
                    </b-form-group>
                  </div>
                  <div class="spaceInputs">
                    <b-form-group
                      label="Liberação de Módulos"
                      label-for="name-aula"
                    >
                      <button @click="openModalModule" class="btnListModule">
                        Configurar Modulos
                      </button>
                    </b-form-group>
                  </div>
                  <div class="spaceInputs">
                    <b-form-group
                      label="Liberação de Aulas"
                      label-for="name-aula"
                    >
                      <button @click="openModalLesson" class="btnListModule">
                        Configurar Aulas
                      </button>
                    </b-form-group>
                  </div>
                  <div class="spaceInputs">
                    <b-form-group
                      label="Liberação do Telegram"
                      label-for="name-aula"
                    >
                      <button @click="openModalTelegram" class="btnListModule">
                        Configurar Telegram
                      </button>
                    </b-form-group>
                  </div>

                  <div class="spaceInputs">
                    <b-form-group
                      label="Comunidade do Circle"
                      label-for="name-aula"
                    >
                      <button
                        @click="openModalComunidade('circle')"
                        class="btnListModule"
                      >
                        Configurar Circle
                      </button>
                    </b-form-group>
                  </div>
                  <div class="spaceInputs">
                    <b-form-group
                      label="Comunidade do Discord"
                      label-for="name-aula"
                    >
                      <button
                        @click="openModalComunidade('discord')"
                        class="btnListModule"
                      >
                        Configurar Discord
                      </button>
                    </b-form-group>
                  </div>
                  <div class="spaceInputs">
                    <b-form-group
                      label="Comunidade do Slack"
                      label-for="name-aula"
                    >
                      <button
                        @click="openModalComunidade('slack')"
                        class="btnListModule"
                      >
                        Configurar Slack
                      </button>
                    </b-form-group>
                  </div>
                </span>

                <div class="spaceInputs">
                  <button class="btn-cancela" @click.prevent="notSaveClass()">
                    Voltar
                  </button>
                  <button class="btn-criar" @click.prevent="createClass()">
                    Salvar esta Turma
                  </button>
                </div>
              </div>
            </div>
            <div class="spaceInputs" v-if="toAddClass === false">
              <b-form-group class="paddingNewClass">
                <div class="btn-newClass" @click="addNewClass()">
                  <p class="text">Adicionar mais uma Turma</p>
                </div>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-modal id="modal-turma-liberation" hide-footer size="xl">
        <div class="AllContent">
          <div class="flexHeader">
            <div>
              <div class="title">Meu Curso</div>
              <div class="subtitle">Configure a liberação do Curso</div>
            </div>
          </div>
          <div class="flexHeader2">
            <div></div>
            <!-- <div class="totalModules">{{ totalModules }} Cursos encontrados</div> -->
          </div>

          <div class="tableModule">
            <div class="header">
              <div>Nome do Curso</div>
              <div>Tipo</div>
              <div>Liberação</div>
              <div>Encerramento</div>
              <div>Ação</div>
            </div>
            <div class="body">
              <div class="text">
                {{
                  AllCourses.title.length > 30
                    ? AllCourses.title.substring(0, 30) + '...'
                    : AllCourses.title
                }}
              </div>
              <div
                class="text"
                v-if="AllCoursesLiberation.rule === 'daysAfterInscription'"
              >
                Por Prazo
              </div>
              <div
                class="text"
                v-else-if="AllCoursesLiberation.rule === 'fixedDate'"
              >
                Por Data
              </div>
              <div class="text" v-else>Liberado</div>

              <div class="text" v-if="AllCoursesLiberation.rule === null">
                Liberado
              </div>
              <div
                class="text"
                v-else-if="AllCoursesLiberation.rule === 'daysAfterInscription'"
              >
                {{ AllCoursesLiberation.dinamicDate.daysToStart }} Dias
              </div>
              <div
                class="text"
                v-else-if="AllCoursesLiberation.rule === 'fixedDate'"
              >
                {{
                  AllCoursesLiberation.fixedDateStart
                    | moment('DD/MM/YYYY HH:mm')
                }}
              </div>

              <div class="text" v-if="AllCoursesLiberation.rule === null">
                Liberado
              </div>
              <div
                class="text"
                v-else-if="AllCoursesLiberation.rule === 'daysAfterInscription'"
              >
                {{
                  AllCoursesLiberation.dinamicDate.daysToEnd
                    ? AllCoursesLiberation.dinamicDate.daysToEnd + ' Dias'
                    : 'Duração indefinida'
                }}
              </div>
              <div
                class="text"
                v-else-if="AllCoursesLiberation.rule === 'fixedDate'"
              >
                {{
                  AllCoursesLiberation.fixedDateEnd
                    ? formatDateTime(AllCoursesLiberation.fixedDateEnd)
                    : 'Duração indefinida'
                }}
              </div>

              <div class="editar" @click="openCursoEdit(AllCourses)">
                Gerenciar
              </div>
            </div>
          </div>
        </div>
        <div class="fundoModalModuleLiberation" v-if="editModuleLiberation">
          <div class="modalModuleLiberation">
            <svg
              @click="closeModal"
              class="close"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 1.42603L1 16.426M1 1.42603L16 16.426"
                stroke="#B5B9C5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="title">Liberação do Curso</div>
            <div class="space animationOpacity2">
              <b-form-group label="Tipo de Liberação" label-for="name-module">
                <b-form-select
                  v-model="selectedType"
                  :options="optionsType"
                  @change="clearForm"
                ></b-form-select>
              </b-form-group>
            </div>
            <br />
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'daysAfterInscription'"
            >
              <b-form-group
                label="Prazo limite para liberar acesso"
                label-for="name-module"
              >
                <b-form-input
                  v-mask="'####'"
                  v-model="daysAfterInscription"
                  placeholder="Em dias"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'daysAfterInscription'"
            >
              <b-form-group
                label="Encerramento do acesso"
                label-for="name-module"
              >
                <b-form-input
                  v-mask="'####'"
                  v-model="daysAfterInscriptionEnd"
                  placeholder="Em dias ou em branco para duração indefinida"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'fixedDate'"
            >
              <b-form-group
                label="Data e hora de liberação"
                label-for="name-module"
              >
                <b-form-input
                  v-mask="'##/##/#### ##:##'"
                  v-model="dataInit"
                  placeholder="Ex: 15/04/2021 15:45"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'fixedDate'"
            >
              <b-form-group
                label="Data e hora de encerramento"
                label-for="name-module"
              >
                <b-form-input
                  v-mask="'##/##/#### ##:##'"
                  v-model="dataFim"
                  placeholder="Ou em branco para duração indefinida"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="space">
              <button class="btn-salvar" @click="salvarEditarCourse">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal id="modal-modules-liberation" hide-footer size="xl">
        <div class="AllContent">
          <div class="flexHeader">
            <div>
              <div class="title">Listagem de Módulos</div>
              <div class="subtitle">Configure suas liberações</div>
            </div>
          </div>
          <div class="flexHeader2">
            <div></div>
            <div class="totalModules">
              {{ totalModules }} Módulos encontrados
            </div>
          </div>

          <div class="tableModule">
            <div class="header">
              <div>Nome do Módulo</div>
              <div>Tipo</div>
              <div>Liberação</div>
              <div>Encerramento</div>
              <div>Ação</div>
            </div>
            <div class="body" v-for="modules in AllModules" :key="modules.id">
              <div class="text">
                {{
                  modules.title.length > 30
                    ? modules.title.substring(0, 30) + '...'
                    : modules.title
                }}
              </div>
              <div class="text" v-if="modules.rule === null">Liberado</div>
              <div
                class="text"
                v-else-if="modules.rule.rule === 'daysAfterInscription'"
              >
                Por prazo
              </div>
              <div class="text" v-else-if="modules.rule.rule === 'fixedDate'">
                Por data
              </div>
              <div class="text" v-else>Liberado</div>

              <div class="text" v-if="modules.rule === null">Liberado</div>
              <div
                class="text"
                v-else-if="modules.rule.rule === 'daysAfterInscription'"
              >
                {{ modules.rule.dinamicDate.daysToStart }} Dias
              </div>
              <div class="text" v-else-if="modules.rule.rule === 'fixedDate'">
                {{ modules.rule.fixedDateStart | moment('DD/MM/YYYY HH:mm') }}
              </div>
              <div class="text" v-else>Liberado</div>

              <div class="text" v-if="modules.rule === null">Liberado</div>
              <div
                class="text"
                v-else-if="
                  modules.rule.rule === 'daysAfterInscription' &&
                  modules.rule.dinamicDate.daysToEnd === 0
                "
              >
                {{
                  modules.rule.dinamicDate.daysToEnd
                    ? modules.rule.dinamicDate.daysToEnd + ' Dias'
                    : 'Duração indefinida'
                }}
              </div>
              <div
                class="text"
                v-else-if="modules.rule.rule === 'daysAfterInscription'"
              >
                {{
                  modules.rule.dinamicDate.daysToEnd
                    ? modules.rule.dinamicDate.daysToEnd + ' Dias'
                    : 'Duração indefinida'
                }}
              </div>
              <div class="text" v-else-if="modules.rule.rule === 'fixedDate'">
                {{
                  modules.rule.fixedDateEnd
                    ? formatDateTime(modules.rule.fixedDateEnd)
                    : 'Duração indefinida'
                }}
              </div>
              <div class="text" v-else>Liberado</div>

              <div class="editar" @click="openModuleEdit(modules)">
                Gerenciar
              </div>
            </div>
          </div>
        </div>
        <div class="fundoModalModuleLiberation" v-if="editModuleLiberation">
          <div class="modalModuleLiberation">
            <svg
              @click="closeModal"
              class="close"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 1.42603L1 16.426M1 1.42603L16 16.426"
                stroke="#B5B9C5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="title">Liberação de Módulo</div>
            <div class="space animationOpacity2">
              <b-form-group label="Tipo de Liberação" label-for="name-module">
                <b-form-select
                  v-model="selectedType"
                  :options="optionsType"
                  @change="clearForm"
                ></b-form-select>
              </b-form-group>
            </div>
            <br />
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'daysAfterInscription'"
            >
              <b-form-group
                label="Prazo limite para liberar acesso"
                label-for="name-module"
              >
                <b-form-input
                  v-mask="'####'"
                  v-model="daysAfterInscription"
                  placeholder="Em dias"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'daysAfterInscription'"
            >
              <b-form-group
                label="Encerramento do acesso"
                label-for="name-module"
              >
                <b-form-input
                  v-mask="'####'"
                  v-model="daysAfterInscriptionEnd"
                  placeholder="Em dias ou em branco para duração indefinida"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'fixedDate'"
            >
              <b-form-group
                label="Data e hora de liberação"
                label-for="name-module"
              >
                <b-form-input
                  v-mask="'##/##/#### ##:##'"
                  v-model="dataInit"
                  placeholder="Ex: 15/04/2021 15:45"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'fixedDate'"
            >
              <b-form-group
                label="Data e hora de encerramento"
                label-for="name-module"
              >
                <b-form-input
                  v-mask="'##/##/#### ##:##'"
                  v-model="dataFim"
                  placeholder="Ou em branco para duração indefinida"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="space">
              <button class="btn-salvar" @click="salvarEditarModule">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal id="modal-lesson-liberation" hide-footer size="xl">
        <div class="AllContent">
          <div class="flexHeader">
            <div>
              <div class="title">Listagem de Aulas</div>
              <div class="subtitle">Configure suas liberações</div>
            </div>
          </div>
          <div class="flexHeader2">
            <div>
              <b-form-select
                @change="selectedModuleinLesson"
                v-model="selectedModuleToClass"
                class="select-filter-options"
              >
                <b-form-select-option
                  v-for="(options, x) in AllModules"
                  :key="x"
                  :value="options.id"
                  >{{ options.title }}</b-form-select-option
                >
              </b-form-select>
            </div>
            <div class="totalModules">{{ totalLesson }} Aulas encontradas</div>
          </div>

          <div class="tableModule">
            <div class="header">
              <div>Nome da Aula</div>
              <div>Tipo</div>
              <div>Liberação</div>
              <div>Encerramento</div>
              <div>Ação</div>
            </div>
            <div class="body" v-for="lesson in AllLessons" :key="lesson.id">
              <div class="text">
                {{
                  lesson.title.length > 30
                    ? lesson.title.substring(0, 30) + '...'
                    : lesson.title
                }}
              </div>

              <div class="text" v-if="lesson.rule == null">Liberado</div>
              <div
                class="text"
                v-else-if="lesson.rule.rule === 'daysAfterInscription'"
              >
                Por prazo
              </div>
              <div class="text" v-else-if="lesson.rule.rule === 'fixedDate'">
                Por data
              </div>
              <div class="text" v-else>Liberado</div>

              <div class="text" v-if="lesson.rule === null">Liberado</div>
              <div
                class="text"
                v-else-if="lesson.rule.rule === 'daysAfterInscription'"
              >
                {{ lesson.rule.dinamicDate.daysToStart }} Dias
              </div>
              <div class="text" v-else-if="lesson.rule.rule === 'fixedDate'">
                {{ lesson.rule.fixedDateStart | moment('DD/MM/YYYY HH:mm') }}
              </div>
              <div class="text" v-else>Liberado</div>

              <div class="text" v-if="lesson.rule === null">Liberado</div>
              <div
                class="text"
                v-else-if="lesson.rule.rule === 'daysAfterInscription'"
              >
                {{
                  lesson.rule.dinamicDate.daysToEnd
                    ? lesson.rule.dinamicDate.daysToEnd + ' Dias'
                    : 'Duração indefinida'
                }}
              </div>
              <div class="text" v-else-if="lesson.rule.rule === 'fixedDate'">
                {{
                  lesson.rule.fixedDateEnd
                    ? formatDateTime(lesson.rule.fixedDateEnd)
                    : 'Duração indefinida'
                }}
              </div>
              <div class="text" v-else>Liberado</div>

              <div class="editar" @click="openLessonEdit(lesson)">
                Gerenciar
              </div>
            </div>
          </div>
        </div>
        <div class="fundoModalModuleLiberation" v-if="editModuleLiberation">
          <div class="modalModuleLiberation">
            <svg
              @click="closeModal"
              class="close"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 1.42603L1 16.426M1 1.42603L16 16.426"
                stroke="#B5B9C5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="title">Liberação de Aulas</div>
            <div class="space animationOpacity2">
              <b-form-group label="Tipo de Liberação" label-for="name-module">
                <b-form-select
                  v-model="selectedType"
                  :options="optionsType"
                  @change="clearForm"
                ></b-form-select>
              </b-form-group>
            </div>
            <br />
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'daysAfterInscription'"
            >
              <b-form-group
                label="Prazo limite para liberar acesso"
                label-for="name-module"
              >
                <b-form-input
                  v-mask="'####'"
                  v-model="daysAfterInscription"
                  placeholder="Em dias"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'daysAfterInscription'"
            >
              <b-form-group
                label="Encerramento do acesso"
                label-for="name-module"
              >
                <b-form-input
                  v-mask="'####'"
                  v-model="daysAfterInscriptionEnd"
                  placeholder="Em dias ou em branco para duração indefinida"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'fixedDate'"
            >
              <b-form-group
                label="Data e hora de liberação"
                label-for="name-module"
              >
                <b-form-input
                  v-mask="'##/##/#### ##:##'"
                  v-model="dataInit"
                  placeholder="Ex: 15/04/2021 15:45"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'fixedDate'"
            >
              <b-form-group
                label="Data e hora de encerramento"
                label-for="name-module"
              >
                <b-form-input
                  v-mask="'##/##/#### ##:##'"
                  v-model="dataFim"
                  placeholder="Ou em branco para duração indefinida"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="space">
              <button class="btn-salvar" @click="salvarEditarLesson">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </b-modal>

      <modal-comunidade
        ref="ModalComunidade"
        :modalId="'modal-comunidade'"
        :title="'Comunidade com o ' + comunidadeName"
        :subtitle="'Disponibilize para a turma o acesso à comunidade através de um link.'"
        :instructionsTitle="'Instruções'"
        :inputLabel="'URL'"
        :comunidadeURL.sync="comunidadeURL"
        :saveButtonLabel="'Salvar'"
        :class_id="this.toEditId"
        :course_id="this.cursoAtual"
        :comunidadeName="comunidadeName"
      >
        <h4 class="instruItem">
          1. Se a comunidade já existe, copie e cole o link no campo de URL
        </h4>
        <h4 class="instruItem">
          2. Caso não possua a comunidade, crie uma dentro do
          <a
            class="comunidadeLink"
            :href="comunidadeBaseLink"
            target="_blank"
            >{{ comunidadeName }}</a
          >
        </h4>
        <h4 class="instruItem">
          3. Em seguida, cole o link da comunidade no campo de URL
        </h4>
        <h4 class="instruItem">
          4. Uma vez configurada, os novos alunos receberão um convite por
          e-mail para entrar na comunidade
        </h4>
        <h4 class="instruItem">
          5. O controle de usuários na comunidade é realizada pelo administrador
          da comunidade
        </h4>
      </modal-comunidade>

      <ModalIntegracaoTelegram
        :AllTelegramIntegrations="AllTelegramIntegrations"
        :showBtnTelegram="showBtnTelegram"
        :selectedType="selectedType"
        :salvarTelegramEdit="salvarTelegramEdit"
        :initTelegramIntegration="initTelegramIntegration"
        :removeTelegramIntegration="removeTelegramIntegration"
        :optionsType="optionsType"
        :daysAfterInscription="daysAfterInscription"
        :daysAfterInscriptionEnd="daysAfterInscriptionEnd"
        :dataInit.sync="dataInit"
        :dataFim.sync="dataFim"
      ></ModalIntegracaoTelegram>

      <ModalDeletarTelegram
        ref="modalDeleteTelegram"
        :listIntegrations="listIntegrationsTelegram"
      />
    </div>
  </Layout>
</template>
<script>
// import Vue from "vue";
import Layout from '@/layout/Layout.vue'

import Slidebar from '@/components/Slidebar.vue'
import ModalUpload from '@/components/ModalUpload.vue'
import ModalCategoria from '@/components/ModalCategoria.vue'
import ModalDeletarTelegram from '@/components/ModalDeletarTelegram.vue'
import { ToggleButton } from 'vue-js-toggle-button'
import CourseService from '@/services/resources/CourseService'
const serviceCourse = CourseService.build()
import ClassIntegrationService from '@/services/resources/ClassIntegrationService'
const serviceClassIntegration = ClassIntegrationService.build()
import LiberationService from '@/services/resources/LiberationService'
const serviceLiberation = LiberationService.build()
import moment from 'moment'
import notify from '@/services/libs/notificacao'
import ModalComunidade from '@/components/ModalComunidade.vue'
import ModalIntegracaoTelegram from '@/components/ModalIntegracaoTelegram.vue'
import { isEmpty } from '@/utils/objects'

export default {
  components: {
    Layout,
    Slidebar,
    ModalUpload,
    ModalCategoria,
    ToggleButton,
    ModalDeletarTelegram,
    ModalComunidade,
    ModalIntegracaoTelegram
  },
  data() {
    return {
      beforeDataInit: '',
      showBtnTelegram: false,
      nameCourse: 'teste',
      nameModulo: '',
      resumoCourse: '',
      descricaoCourse: '',
      categoryCourse: '',
      btnText: 'Criar curso agora',
      titleText: 'Crie o seu curso',
      step: 0,
      AllCategory: 1,
      data: 'introducao',
      midiaintroducao: '',
      midiacapa: '',
      midiaaulas: '',
      cursoAtual: '0',
      myDataVariable: true,
      existParans: false,
      rowClass: 'row-table-body',
      emptyText: 'Sem Resultados',
      filterOn: [],
      fieldsx: [
        { key: 'title', label: 'Turma' },
        { key: 'students_count', label: 'Qtd Alunos' },
        { key: 'updated_at', label: 'Criada em' },
        { key: 'created_at', label: 'Ações' }
      ],
      AllClass: [],
      toAddClass: false,
      nameClass: '',
      toEdit: false,
      toEditId: 0,
      textAction: 'Criar Turma',
      AllModules: [],
      totalModules: 0,
      editModuleLiberation: false,
      selectedModuleEdit: [],
      selectedLessonEdit: [],
      selectedTelegramEdit: [],
      dataInit: '',
      dataFim: '',
      existLiberation: {},
      selectedModuleToClass: {},
      getEditLessons: false,
      AllLessons: [],
      AllTelegramIntegrations: [],
      totalLesson: '',
      selectedType: 'fixedDate',
      optionsType: [
        { value: null, text: 'Liberado sem regra' },
        { value: 'fixedDate', text: 'Por data' },
        { value: 'daysAfterInscription', text: 'Por prazo' }
      ],
      daysAfterInscription: '',
      daysAfterInscriptionEnd: '',
      AllCourses: [],
      AllCoursesLiberation: {
        rule: null
      },
      comunidades: [
        {
          circle: {
            name: 'Circle',
            comunidadeBaseLink: 'https://circle.so/',
            comunidadeURL: null
          }
        },
        {
          discord: {
            name: 'Discord',
            comunidadeBaseLink: 'https://discord.com/',
            comunidadeURL: null
          }
        },
        {
          slack: {
            name: 'Slack',
            comunidadeBaseLink: 'https://slack.com/',
            comunidadeURL: null
          }
        }
      ],
      comunidadeName: '',
      comunidadeURL: null,
      comunidadeBaseLink: ''
    }
  },
  computed: {
    startDateFormatted: {
      get() {
        let val = this.dataInit
        if (val) {
          val = moment(val, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')
        }

        return val
      }
    },
    endDateFormatted: {
      get() {
        if (this.dataFim) {
          return moment(this.dataFim, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')
        }

        return null
      }
    }
  },
  methods: {
    viewCourse() {
      serviceCourse.read(this.cursoAtual + '/module').then(resp => {
        if (resp.length === 0) {
          notify('erro', 'Nenhum Módulo Configurado!')
          return false
        }
        let routeData = this.$router.resolve('/curso/' + this.cursoAtual)
        window.open(routeData.href, '_blank')
      })
    },
    enventSwitchStatus() {
      var xxxStatus = ''
      if (this.myDataVariable === true) {
        xxxStatus = 'published'
      } else {
        xxxStatus = 'draft'
      }
      let data = {
        id: this.cursoAtual,
        status: xxxStatus
      }
      this.$root.$emit('loadOn')
      serviceCourse
        .postID({ id: this.cursoAtual, status: xxxStatus })
        .then(resp => {
          this.$root.$emit('loadOff')
          this.getCourse()
        })
        .catch(err => {
          this.$root.$emit('loadOff')
        })
    },
    selectedModuleinLesson() {
      this.getLessons()
    },
    salvarEditarLesson() {
      let data = null;

      if (isEmpty(this.existLiberation?.id)) {
        if (this.selectedType === null) {
          this.closeModal()
          return false
        } 
        else if (this.selectedType === 'fixedDate') {
          if (!this.dataInit) {
            notify('erro', 'Data inicial não informada!')
            return false
          }

          data = {
            id: 'lesson',
            rule: 'fixedDate',
            fixedDateStart: this.startDateFormatted,
            fixedDateEnd: this.endDateFormatted,
            lesson_id: this.selectedLessonEdit.id,
            course_class_id: this.toEditId
          }
        } 
        else if (this.selectedType === 'daysAfterInscription') {
          if (!this.daysAfterInscription) {
            return false
          }

          data = {
            id: 'lesson',
            rule: 'daysAfterInscription',
            daysToStart: parseInt(this.daysAfterInscription, 10),
            daysToEnd: parseInt(this.daysAfterInscriptionEnd, 10),
            lesson_id: this.selectedLessonEdit.id,
            course_class_id: this.toEditId
          }
        }

        serviceLiberation
          .postID(data)
          .then(resp => {
            this.updateDone()
            this.closeModal()
            this.getLessons()
            this.$root.$emit('loadOff')
          })
          .catch(err => {
            this.updateFailed()
            this.$root.$emit('loadOff')
          })
      } 
      else {
        if (this.selectedType === null) {
          serviceLiberation
            .destroy('lesson/' + this.existLiberation.id)
            .then(resp => {
              this.updateDone()
              this.closeModal()
              this.getLessons()
              this.$root.$emit('loadOff')
            })
            .catch(err => {
              this.updateFailed()
              this.$root.$emit('loadOff')
            })

          return false
        } 
        else if (this.selectedType === 'fixedDate') {
          if (!this.dataInit) {
            return false
          }

          data = {
            id: 'lesson/' + this.existLiberation.id,
            rule: 'fixedDate',
            fixedDateStart: this.startDateFormatted,
            fixedDateEnd: this.endDateFormatted,
            lesson_id: this.selectedLessonEdit.id,
            course_class_id: this.toEditId
          }
        } 
        else if (this.selectedType === 'daysAfterInscription') {
          if (!this.daysAfterInscription) {
            return false
          }

          data = {
            id: 'lesson/' + this.existLiberation.id,
            rule: 'daysAfterInscription',
            daysToStart: parseInt(this.daysAfterInscription, 10),
            daysToEnd: parseInt(this.daysAfterInscriptionEnd, 10),
            lesson_id: this.selectedLessonEdit.id,
            course_class_id: this.toEditId
          }
        }

        serviceLiberation
          .postID(data)
          .then(resp => {
            this.updateDone()
            this.closeModal()
            this.getLessons()
            this.$root.$emit('loadOff')
          })
          .catch(err => {
            this.updateFailed()
            this.$root.$emit('loadOff')
          })
      }
    },
    salvarTelegramEdit() {
      var data = null
      var isvalid = false
      var isvalid2 = false
      if (this.selectedType === null) {
        notify('sucesso', 'A Integração está em processamento!')
        this.selectedTelegramEdit = []
        return false
      } else if (this.selectedType === 'fixedDate') {
        var data1 = this.dataInit
        const data1moment = moment(data1, 'DD/MM/YYYY HH:mm')

        if (!data1) {
          notify('erro', 'Informe a data de início!')
        } else if (!data1moment.isValid()) {
          notify('erro', 'Data de início inválida!')
        } else if (data1.split(' ').length < 2) {
          notify('erro', 'Inofrme a data e hora de liberação corretamente!')
        } else if (data1.length < 16) {
          notify('erro', 'Data de início com tamanho inválido!')
        } else if (
          this.dataFim &&
          moment(this.dataFim).isValid() &&
          data1moment.isAfter(moment(this.dataFim))
        ) {
          notify('erro', 'Data de fim não pode ser menor que a data de início!')
        } else {
          isvalid = true
          data1 = data1.split('/')
          var data1hora = data1[2].split(' ')
          var data1result =
            data1hora[0] + '-' + data1[1] + '-' + data1[0] + ' ' + data1hora[1]
        }

        var data2 = this.dataFim
        const data2moment = moment(data2, 'DD/MM/YYYY HH:mm')
        if (!data2) {
          data2result = null
          isvalid2 = true
        } else if (data2) {
          if (!data2moment.isValid()) {
            notify('erro', 'Informe a data e hora de finalização corretamente!')
          } else if (data2.split(' ').length < 2) {
            notify('erro', 'Informe a data e hora de finalização corretamente!')
          } else if (data2.length < 16) {
            notify('erro', 'Informe a data e hora de finalização corretamente!')
          } else if (data2moment.isBefore(moment(data1result))) {
            notify('erro', 'Informe a data e hora de finalização corretamente!')
          } else {
            isvalid2 = true
            data2 = data2.split('/')
            var data2hora = data2[2].split(' ')
            var data2result =
              data2hora[0] +
              '-' +
              data2[1] +
              '-' +
              data2[0] +
              ' ' +
              data2hora[1]
          }
        }

        data = {
          id: this.AllTelegramIntegrations.filter(i => i.status === 'draft')[0]
            .id,
          rule: 'fixed',
          dinamic_date: {
            fixedDateStart: moment(data1result).format('YYYY-MM-DD HH:mm'),
            fixedDateEnd:
              data2result === null
                ? null
                : moment(data2result).format('YYYY-MM-DD HH:mm')
          }
        }
      } else if (this.selectedType === 'daysAfterInscription') {
        if (
          (this.daysAfterInscriptionEnd &&
            this.daysAfterInscription > this.daysAfterInscriptionEnd) ||
          this.daysAfterInscription < 0
        ) {
          notify('erro', 'Informe a data e hora de liberação corretamente!')
        } else {
          isvalid = true
          isvalid2 = true
        }
        data = {
          id: this.AllTelegramIntegrations.filter(i => i.status === 'draft')[0]
            .id,
          rule: 'dinamic',
          dinamic_date: {
            daysToStart: parseInt(this.daysAfterInscription, 10),
            daysToEnd: parseInt(this.daysAfterInscriptionEnd, 10)
          }
        }
      }

      if (isvalid && isvalid2) {
        serviceClassIntegration
          .update(data)
          .then(() => {
            this.$root.$emit('loadOff')
            this.listIntegrationsTelegram()
            notify('sucesso', 'Integração Salva com Sucesso!')
          })
          .catch(err => {
            this.$root.$emit('loadOff')
          })
      }
    },
    salvarEditarModule() {
      let data = null

      if (isEmpty(this.existLiberation?.id)) {
        if (this.selectedType === null) {
          this.closeModal()
          return false
        } 
        else if (this.selectedType === 'fixedDate') {
          if (!this.dataInit) {
            return false
          }

          data = {
            id: 'module/',
            rule: 'fixedDate',
            fixedDateStart: this.startDateFormatted,
            fixedDateEnd: this.endDateFormatted,
            module_id: this.selectedModuleEdit.id,
            course_class_id: this.toEditId
          }
        } 
        else if (this.selectedType === 'daysAfterInscription') {
          data = {
            id: 'module/',
            rule: 'daysAfterInscription',
            daysToStart: parseInt(this.daysAfterInscription, 10),
            daysToEnd: parseInt(this.daysAfterInscriptionEnd, 10),
            module_id: this.selectedModuleEdit.id,
            course_class_id: this.toEditId
          }
        }

        serviceLiberation
          .postID(data)
          .then(resp => {
            this.closeModal()
            this.getModules()
            this.$root.$emit('loadOff')
          })
          .catch(err => {
            this.updateFailed()
            this.$root.$emit('loadOff')
          })
      } 
      else {
        if (this.selectedType === null) {
          serviceLiberation
            .destroy('module/' + this.existLiberation.id)
            .then(resp => {
              this.updateDone()
              this.closeModal()
              this.getModules()
              this.$root.$emit('loadOff')
            })
            .catch(err => {
              this.updateFailed()
              this.$root.$emit('loadOff')
            })
          return false
        } 
        else if (this.selectedType === 'fixedDate') {
          if (!this.dataInit) {
            return false
          }

          data = {
            id: 'module/' + this.existLiberation.id,
            rule: 'fixedDate',
            fixedDateStart: this.startDateFormatted,
            fixedDateEnd: this.endDateFormatted,
            module_id: this.selectedModuleEdit.id,
            course_class_id: this.toEditId
          }
        } 
        else if (this.selectedType === 'daysAfterInscription') {
          if (!this.daysAfterInscription) {
            return false
          }

          data = {
            id: 'module/' + this.existLiberation.id,
            rule: 'daysAfterInscription',
            daysToStart: parseInt(this.daysAfterInscription, 10),
            daysToEnd: parseInt(this.daysAfterInscriptionEnd, 10),
            module_id: this.selectedLessonEdit.id,
            course_class_id: this.toEditId
          }
        }

        serviceLiberation
          .postID(data)
          .then(resp => {
            this.updateDone()
            this.closeModal()
            this.getModules()
            this.$root.$emit('loadOff')
          })
          .catch(err => {
            this.updateFailed()
            this.$root.$emit('loadOff')
          })
      }
    },
    salvarEditarCourse() {
      let data = null

      if (isEmpty(this.AllCoursesLiberation.id)) {
        if (this.selectedType === null) {
          this.closeModal()
          return false
        } 
        else if (this.selectedType === 'fixedDate') {
          if (!this.dataInit) {
            return false
          }

          data = {
            id: 'course/',
            rule: 'fixedDate',
            fixedDateStart: this.startDateFormatted,
            fixedDateEnd: this.endDateFormatted,
            course_id: this.AllCourses.id,
            course_class_id: this.toEditId
          }
        } else if (this.selectedType === 'daysAfterInscription') {
          if (!this.daysAfterInscription) {
            return false
          }

          data = {
            id: 'course/',
            rule: 'daysAfterInscription',
            daysToStart: parseInt(this.daysAfterInscription, 10),
            daysToEnd: parseInt(this.daysAfterInscriptionEnd, 10),
            course_id: this.AllCourses.id,
            course_class_id: this.toEditId
          }
        }

        serviceLiberation
          .postID(data)
          .then(resp => {
            this.updateDone()
            this.closeModal()
            this.getCourse()
            this.$root.$emit('loadOff')
          })
          .catch(err => {
            this.updateFailed()
            this.$root.$emit('loadOff')
          })
      } else {
        if (this.selectedType === null) {
          serviceLiberation
            .destroy('course/' + this.existLiberation.id)
            .then(resp => {
              this.updateDone()
              this.closeModal()
              this.getCourse()
              this.$root.$emit('loadOff')
            })
            .catch(err => {
              this.updateFailed()
              this.$root.$emit('loadOff')
            })
          return false
        } 
        else if (this.selectedType === 'fixedDate') {
          if (!this.dataInit) {
            return false
          }

          data = {
            id: 'course/' + this.existLiberation.id,
            rule: 'fixedDate',
            fixedDateStart: this.startDateFormatted,
            fixedDateEnd: this.endDateFormatted,
            course_id: this.AllCourses.id,
            course_class_id: this.toEditId
          }
        } 
        else if (this.selectedType === 'daysAfterInscription') {
          if (!this.daysAfterInscription) {
            return false
          }

          data = {
            id: 'course/' + this.existLiberation.id,
            rule: 'daysAfterInscription',
            daysToStart: parseInt(this.daysAfterInscription, 10),
            daysToEnd: parseInt(this.daysAfterInscriptionEnd, 10),
            course_id: this.AllCourses.id,
            course_class_id: this.toEditId
          }
        }
        serviceLiberation
          .postID(data)
          .then(resp => {
            this.updateDone()
            this.closeModal()
            this.getCourse()
            this.$root.$emit('loadOff')
          })
          .catch(err => {
            this.updateFailed()
            this.$root.$emit('loadOff')
          })
      }
    },
    openLessonEdit(data) {
      this.existLiberation = !isEmpty(data.rule) ? data.rule : null 

      this.editModuleLiberation = true
      this.selectedLessonEdit = data
      
      if (data.rule === null) {
        this.selectedType = null
        return false
      } else {
        if (data.rule.rule === 'daysAfterInscription') {
          if (isEmpty(data?.rule?.dinamicDate?.daysToStart)) {
            return false
          }

          this.selectedType = 'daysAfterInscription'
          this.daysAfterInscription = data.rule.dinamicDate.daysToStart?.toString()
          this.daysAfterInscriptionEnd = data.rule.dinamicDate.daysToEnd?.toString()
        } else {
          if (!data.rule.fixedDateStart) {
            return false
          }

          this.selectedType = 'fixedDate'
          this.dataInit = moment(data.rule.fixedDateStart).format(
            'DD-MM-YYYY HH:mm'
          )
          this.dataFim = moment(data.rule.fixedDateEnd).format(
            'DD-MM-YYYY HH:mm'
          )
        }
      }

      const id = `lesson/${this.selectedLessonEdit.id}/course-class/${this.toEditId}`
      serviceLiberation
        .read(id)
        .then(resp => {
          this.existLiberation = resp
          this.$root.$emit('loadOff')
        })
        .catch(err => {
          //console.error(err)
          this.existLiberation = null
          this.$root.$emit('loadOff')
        })
    },
    openCursoEdit(data) {
      this.editModuleLiberation = true
      this.selectedCourseEdit = data
      if (this.AllCoursesLiberation.rule === null) {
        this.selectedType = null
      } 
      else {
        if (this.AllCoursesLiberation.rule === 'daysAfterInscription') {
          if (!this.AllCoursesLiberation.dinamicDate.daysToStart) return false
          this.selectedType = 'daysAfterInscription'
          this.daysAfterInscription =
            this.AllCoursesLiberation.dinamicDate.daysToStart
          this.daysAfterInscriptionEnd =
            this.AllCoursesLiberation.dinamicDate.daysToEnd
        } else {
          if (!this.AllCoursesLiberation.fixedDateStart) return false
          this.selectedType = 'fixedDate'
          this.dataInit = moment(
            this.AllCoursesLiberation.fixedDateStart
          ).format('DD-MM-YYYY HH:mm')
          this.dataFim = moment(this.AllCoursesLiberation.fixedDateEnd).format(
            'DD-MM-YYYY HH:mm'
          )
        }
      }
      this.existLiberation = this.AllCoursesLiberation
    },
    openModuleEdit(data) {
      this.editModuleLiberation = true
      this.selectedModuleEdit = data
      if (data.rule === null) {
        this.selectedType = null
      } else {
        this.existLiberation = data.rule;
        if (data.rule.rule === 'daysAfterInscription') {
          if (data.rule.dinamicDate.daysToStart) {
            this.selectedType = 'daysAfterInscription'
            this.daysAfterInscription = data.rule.dinamicDate.daysToStart?.toString()
            this.daysAfterInscriptionEnd = data.rule.dinamicDate.daysToEnd?.toString()
          }
        } else {
          if (data.rule.fixedDateStart) {
            this.selectedType = 'fixedDate'
            this.dataInit = moment(data.rule.fixedDateStart).format(
              'DD-MM-YYYY HH:mm'
            )
            this.dataFim = moment(data.rule.fixedDateEnd).format(
              'DD-MM-YYYY HH:mm'
            )
          }
        }
      }

      const id = `module/${this.selectedModuleEdit.id}/course-class/${this.toEditId}`
      serviceLiberation
        .read(id)
        .then(resp => {
          this.existLiberation = resp
          this.$root.$emit('loadOff')
        })
        .catch(err => {
          this.existLiberation = null
          this.$root.$emit('loadOff')
        })
    },
    updateDone(msg = null) {
      notify('sucesso', msg ?? 'Alterações aplicadas.')
    },
    updateFailed(msg = null) {
      notify('erro', msg ?? 'Não foi possível aplicar as alterações.')
    },
    clearForm() {
      this.$nextTick(() => {
        this.daysAfterInscription = null
        this.daysAfterInscriptionEnd = null
        this.dataInit = null
        this.dataFim = null
      })
    },
    closeModal() {
      this.clearForm()
      this.editModuleLiberation = false
      this.selectedLessonEdit = []
    },
    howToEdit(item) {
      this.addNewClass()
      this.toEdit = true
      this.toEditId = item.id
      this.nameClass = item.title
      this.textAction = 'Editar Turma'
    },
    openModalTurma() {
      ;(this.getEditLessons = false), this.getCourse()
      this.$root.$emit('bv::show::modal', 'modal-turma-liberation', '#btnShow')
    },
    openModalModule() {
      ;(this.getEditLessons = false), this.getModules()
      this.$root.$emit(
        'bv::show::modal',
        'modal-modules-liberation',
        '#btnShow'
      )
    },
    openModalLesson() {
      this.getEditLessons = true
      this.getModules()
      this.$root.$emit('bv::show::modal', 'modal-lesson-liberation', '#btnShow')
    },
    openModalTelegram() {
      this.listIntegrationsTelegram()
      this.$root.$emit(
        'bv::show::modal',
        'modal-telegram-liberation',
        '#btnShow'
      )
    },
    openModalComunidade(option) {
      var md = this.$refs.ModalComunidade
      if (md) {
        md.reset()
      }
      this.setComunidade(option)
      this.$root.$emit('bv::show::modal', 'modal-comunidade', '#btnShow')

      if (md) {
        md.getComunidade(option)
      }
    },
    setComunidade(option) {
      for (const comunidade of this.comunidades) {
        if (comunidade.hasOwnProperty(option)) {
          this.comunidadeName = comunidade[option].name
          this.comunidadeBaseLink = comunidade[option].comunidadeBaseLink
          this.comunidadeURL = comunidade[option].comunidadeURL
          return
        }
      }
      return `A opção "${option}" não foi encontrada nas comunidades.`
    },
    removeTelegramIntegration(integration, cancel) {
      this.$refs.modalDeleteTelegram.open(integration, cancel)
    },
    getLessons() {
      this.$root.$emit('loadOn')
      serviceCourse
        .read(
          this.cursoAtual +
            '/module/' +
            this.selectedModuleToClass +
            '/lesson/course-class/' +
            this.toEditId +
            '/liberation-rule'
        )
        .then(resp => {
          for (let i = 0; i < resp.length; i++) {
            if (resp[i].rule !== null) {
              resp[i].rule.dinamicDate = JSON.parse(resp[i].rule.dinamicDate)
            }
          }

          this.AllLessons = resp
          this.totalLesson = resp.length
          this.$root.$emit('loadOff')
        })
        .catch(err => {
          //console.log(err);
          this.$root.$emit('loadOff')
        })
    },
    getModules() {
      this.$root.$emit('loadOn')
      serviceCourse
        .read(
          this.cursoAtual +
            '/module/course-class/' +
            this.toEditId +
            '/liberation-rule'
        )
        .then(resp => {
          for (let i = 0; i < resp.length; i++) {
            if (resp[i].rule !== null) {
              resp[i].rule.dinamicDate = JSON.parse(resp[i].rule.dinamicDate)
            }
          }
          this.AllModules = resp
          this.totalModules = resp.length
          if (this.getEditLessons === true) {
            this.selectedModuleToClass = resp[0].id
            this.getLessons()
          }
          this.$root.$emit('loadOff')
        })
        .catch(err => {
          //console.log(err);
          this.$root.$emit('loadOff')
        })
    },
    deleteClass(id) {
      this.$root.$emit('loadOn')
      serviceCourse
        .destroy(this.cursoAtual + '/class/' + id)
        .then(resp => {
          //console.log("delete class", resp);
          this.$root.$emit('loadOff')
          this.getClass()
          notify('sucesso', 'Turma deletada com Sucesso!')
        })
        .catch(err => {
          //console.log(err);
          var error = JSON.parse(err.response.data)
          var msg = ''
          for (var indice in error) {
            msg += error[indice][0] + '<br>'
          }
          if (msg !== '') {
            notify('erro', msg)
          }
          this.$root.$emit('loadOff')
        })
    },
    initTelegramIntegration() {
      this.$root.$emit('loadOn')
      const class_id = this.toEditId
      serviceClassIntegration
        .postID({ id: `?class_id=${class_id}&type=telegram` })
        .then(resp => {
          // console.log("init telegram", resp);
          this.$root.$emit('loadOff')
          this.listIntegrationsTelegram()
          // notify("sucesso", "Integração Telegram Iniciada!");
        })
        .catch(err => {
          //console.log(err);
          this.$root.$emit('loadOff')
          var error = JSON.parse(err.response.data)
          var msg = ''
          for (var indice in error) {
            msg += error[indice][0] + '<br>'
          }
          if (msg !== '') {
            notify('erro', msg)
          }
        })
    },
    listIntegrationsTelegram() {
      this.$root.$emit('loadOn')
      const id = this.toEditId
      serviceClassIntegration
        .read({ id })
        .then(resp => {
          this.AllTelegramIntegrations = resp.map(item => {
            return {
              ...item,
              dinamic_date: item.dinamic_date
                ? JSON.parse(item.dinamic_date)
                : null
            }
          })
          const itemPending = resp.filter(i => i.status === 'draft')
          if (itemPending.length) {
            this.selectedTelegramEdit = itemPending[0]
            ;(this.selectedTelegramEdit.dinamic_date = this.selectedTelegramEdit
              .dinamic_date
              ? JSON.parse(this.selectedTelegramEdit.dinamic_date)
              : null),
              this
          } else {
            this.selectedTelegramEdit = []
          }
          if (!resp.length) {
            this.showBtnTelegram = true
          }
          this.$root.$emit('loadOff')
          this.$forceUpdate()
          // notify("sucesso", "Integração Telegram Iniciada!");
        })
        .catch(err => {
          this.$root.$emit('loadOff')
          var error = JSON.parse(err.response.data)
          var msg = ''
          for (var indice in error) {
            msg += error[indice][0] + '<br>'
          }
          if (msg !== '') {
            notify('erro', msg)
          }
        })
    },
    createClass() {
      if (this.toEdit === false) {
        this.$root.$emit('loadOn')
        serviceCourse
          .postID({ id: this.cursoAtual + '/class', title: this.nameClass })
          .then(resp => {
            this.$root.$emit('loadOff')
            this.toEdit = true
            this.toEditId = resp.id
            this.nameClass = resp.title
            this.textAction = 'Editar Turma'
            this.getClass()
            notify('sucesso', 'Turma salva com Sucesso!')
          })
          .catch(err => {
            //console.log(err);
            var error = JSON.parse(err.response.data)
            var msg = ''
            for (var indice in error) {
              msg += error[indice][0] + '<br>'
            }
            if (msg !== '') {
              notify('erro', msg)
            }
            this.$root.$emit('loadOff')
          })
      } else {
        this.$root.$emit('loadOn')
        serviceCourse
          .postID({
            id: this.cursoAtual + '/class/' + this.toEditId,
            title: this.nameClass
          })
          .then(resp => {
            this.$root.$emit('loadOff')
            this.toEdit = false
            this.toEditId = ''
            this.notSaveClass()
            this.getClass()
            notify('sucesso', 'Turma salva com Sucesso!')
          })
          .catch(err => {
            //console.log(err);
            var error = JSON.parse(err.response.data)
            var msg = ''
            for (var indice in error) {
              msg += error[indice][0] + '<br>'
            }
            if (msg !== '') {
              notify('erro', msg)
            }
            this.$root.$emit('loadOff')
          })
      }
    },
    notSaveClass() {
      this.toAddClass = false
    },
    addNewClass() {
      this.toAddClass = true
      this.textAction = 'Criar Turma'
    },
    pushToList(id) {
      this.$router.push('/config_curso/class/' + id)
    },
    getCourse() {
      this.$root.$emit('loadOn')
      let data = {
        id: this.cursoAtual
      }
      serviceCourse
        .read(data)
        .then(resp => {
          this.AllCourses = resp

          if (this.toEditId == 0 || this.toEditId == null) {
            return;
          }

          serviceLiberation
            .read(
              'course/' + this.AllCourses.id + '/course-class/' + this.toEditId
            )
            .then(resp => {
              resp.dinamicDate = JSON.parse(resp.dinamicDate)
              this.existLiberation = resp
              this.AllCoursesLiberation = resp
            })
            .catch(err => {
              //console.log(err);
              if (err.response.status === 404) {
                let data = {
                  id: null,
                  rule: null
                }
                this.AllCoursesLiberation = data
              }
            })
          this.$root.$emit('loadOff')
        })
        .catch(err => {
          //console.log(err);
        })
    },
    getClass() {
      let params = this.$route.params.curso

      if (params === null || params === undefined || params === '') {
        this.$router.push('/course')
      } else {
        this.cursoAtual = params
        this.existParans = false
        this.$root.$emit('loadOn')
        serviceCourse
          .read(params + '/class')
          .then(resp => {
            this.AllClass = resp
            this.$root.$emit('loadOff')
            this.getCourse()
          })
          .catch(err => {
            //console.log(err);
          })
      }
    },
    formatDateTime(dateTime) {
      return moment(dateTime).format('DD/MM/YYYY HH:mm')
    }
  },
  mounted() {
    this.getClass()
  }
}
</script>
<style lang="scss">
.btn-criar {
  background: var(--maincolor);
  border: 1px solid var(--maincolor);
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  border-radius: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  width: 100%;
  height: 55px;
  margin-top: 20px;
}

#modal-lesson-liberation {
  header {
    border: none !important;
  }
  .select-filter-options {
    position: relative;
    top: 20px;
  }
  select {
    border: 1px solid var(--bordercolor);
    box-sizing: border-box;
    border-radius: 30px;
    height: 50px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #6d767e;
    overflow: hidden !important;
    resize: none !important;
  }
  select:hover {
    border: 1px solid var(--maincolor);
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
  }
  .fundoModalModuleLiberation {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--fundomodal);
    z-index: 999;
    .modalModuleLiberation {
      position: fixed;
      z-index: 9999;
      background-color: var(--backgroundcolor);
      top: 0;
      right: 0;
      height: 100%;
      width: 450px;
      padding: 50px;
      border-radius: 5px 0px 0px 5px;
      .close {
        cursor: pointer;
      }
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        color: var(--fontcolor) !important;
      }
      .space {
        margin-top: 35px;
        input {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 40px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #333;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        input:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        label {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: var(--fontcolor);
        }
        select {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 45px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #6d767e;
          overflow: hidden !important;
          resize: none !important;
        }
        .btn-salvar {
          background: var(--maincolor);
          border-radius: 30px;
          border: none;
          width: 146px;
          font-family: Inter;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 40px;
        }
      }
    }
  }
  .AllContent {
    padding: 0 30px 30px 30px;
    .totalModules {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #81858e;
      margin-top: 30px;
    }
    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      letter-spacing: 0.2px;
      color: var(--fontcolor);
      margin-bottom: 5px;
    }
    .subtitle {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: #81858e;
    }
    .flexHeader {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.5px solid #ededf0;
      padding-bottom: 30px;
    }
    .flexHeader2 {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
    }
    .tableModule {
      width: 100%;
      height: 41vh;
      overflow: auto;
      margin-top: 30px;
      .header {
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 2fr 1fr;
        margin-bottom: 20px;
        div {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          letter-spacing: 0.2px;
          color: var(--fontcolor);
        }
      }
      .body {
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 2fr 1fr;
        border-bottom: 0.5px solid #ededf0;
        height: 40px;
        margin: 15px 5px;
        .text {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          letter-spacing: 0.2px;
          color: #81858e;
        }
        .editar {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          letter-spacing: 0.2px;
          color: var(--maincolor);
          cursor: pointer;
        }
      }
    }
  }
}
#modal-modules-liberation {
  header {
    border: none !important;
  }
  .fundoModalModuleLiberation {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--fundomodal);
    z-index: 999;
    .modalModuleLiberation {
      position: fixed;
      z-index: 9999;
      background-color: var(--backgroundcolor);
      top: 0;
      right: 0;
      height: 100%;
      width: 450px;
      padding: 50px;
      border-radius: 5px 0px 0px 5px;
      .close {
        cursor: pointer;
      }
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        color: var(--fontcolor) !important;
      }
      .space {
        margin-top: 35px;
        // margin-bottom: 40px;
        input {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 40px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #333;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        input:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        select {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 40px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #6d767e;
          overflow: hidden !important;
          resize: none !important;
        }
        label {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: var(--fontcolor);
        }
        .btn-salvar {
          background: var(--maincolor);
          border-radius: 30px;
          border: none;
          width: 146px;
          font-family: Inter;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 40px;
        }
      }
    }
  }
  .AllContent {
    padding: 0 30px 30px 30px;
    .totalModules {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #81858e;
      margin-top: 30px;
    }
    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      letter-spacing: 0.2px;
      color: var(--fontcolor);
      margin-bottom: 5px;
    }
    .subtitle {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: #81858e;
    }
    .flexHeader {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.5px solid #ededf0;
      padding-bottom: 30px;
    }
    .flexHeader2 {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
    }
    .tableModule {
      width: 100%;
      height: 41vh;
      overflow: auto;
      margin-top: 30px;
      .header {
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 2fr 1fr;
        margin-bottom: 20px;
        div {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          letter-spacing: 0.2px;
          color: var(--fontcolor);
        }
      }
      .body {
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 2fr 1fr;
        border-bottom: 0.5px solid #ededf0;
        height: 40px;
        margin: 15px 5px;
        .text {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          letter-spacing: 0.2px;
          color: #81858e;
        }
        .editar {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          letter-spacing: 0.2px;
          color: var(--maincolor);
          cursor: pointer;
        }
      }
    }
  }
}

#modal-turma-liberation {
  header {
    border: none !important;
  }
  .fundoModalModuleLiberation {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--fundomodal);
    z-index: 999;
    .modalModuleLiberation {
      position: fixed;
      z-index: 9999;
      background-color: var(--backgroundcolor);
      top: 0;
      right: 0;
      height: 100%;
      width: 450px;
      padding: 50px;
      border-radius: 5px 0px 0px 5px;
      .close {
        cursor: pointer;
      }
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        color: var(--fontcolor) !important;
      }
      .space {
        margin-top: 35px;
        // margin-bottom: 40px;
        input {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 40px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #333;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        input:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        select {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 40px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #6d767e;
          overflow: hidden !important;
          resize: none !important;
        }
        label {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: var(--fontcolor);
        }
        .btn-salvar {
          background: var(--maincolor);
          border-radius: 30px;
          border: none;
          width: 146px;
          font-family: Inter;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 40px;
        }
      }
    }
  }
  .AllContent {
    padding: 0 30px 30px 30px;
    .totalModules {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #81858e;
      margin-top: 30px;
    }
    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      letter-spacing: 0.2px;
      color: var(--fontcolor);
      margin-bottom: 5px;
    }
    .subtitle {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: #81858e;
    }
    .flexHeader {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.5px solid #ededf0;
      padding-bottom: 30px;
    }
    .flexHeader2 {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
    }
    .tableModule {
      width: 100%;
      height: 41vh;
      overflow: auto;
      margin-top: 30px;
      .header {
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 2fr 1fr;
        margin-bottom: 20px;
        div {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          letter-spacing: 0.2px;
          color: var(--fontcolor);
        }
      }
      .body {
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 2fr 1fr;
        border-bottom: 0.5px solid #ededf0;
        height: 40px;
        margin: 15px 5px;
        .text {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          letter-spacing: 0.2px;
          color: #81858e;
        }
        .editar {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          letter-spacing: 0.2px;
          color: var(--maincolor);
          cursor: pointer;
        }
      }
    }
  }
}

.createClass {
  .btnListModule {
    height: 45px;
    width: 300px;
    background: var(--maincolor);
    border-radius: 30px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--buttonTextColor);
    border: none;
    padding: 0 10px;
  }
  .actionData {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .editar {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 0.2px;
      color: #81858e;
      cursor: pointer;
    }
    .deletar {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 0.2px;
      color: #ff0c37;
      cursor: pointer;
    }
  }
  img.spaceDeleteSvg {
    margin-left: 10px;
    margin-right: 10px;
  }
  .paddingNewClass {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 10px;
  }
  .btn-cancela {
    background: #f7f7f7;
    border: 1px solid#f7f7f7;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    border-radius: 30px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #81858e;
    width: 100%;
    height: 55px;
    margin-top: 40px;
  }
  .btn-newClass {
    background: rgba(129, 133, 142, 0.03);
    border: 2px dashed #81858e;
    box-sizing: border-box;
    border-radius: 30px;
    height: 55px;
    cursor: pointer;
    .text {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #81858e;
      display: flex;
      justify-content: center;
      margin-top: 17px;
    }
  }
  .flexItem {
    display: flex;
    width: 65%;
    justify-content: space-between;
  }
  .removeLink {
    text-decoration: none;
  }
  .table {
    margin-top: 40px;
    .pointer {
      cursor: pointer;
    }
    .table-sm th,
    .table-sm td {
      padding: 0.5rem !important;
    }
    th {
      border: none !important;
      font-family: Inter !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 14px !important;
      letter-spacing: 0.2px !important;
      color: var(--fontcolor) !important;
    }
    th div {
      color: var(--fontcolor);
    }
    tr.row-table-body {
      line-height: 40px !important;
    }
    td {
      border-top: none !important;
      border-bottom: 1px solid #e5e5e5 !important;
    }
    .table thead th {
      border: none !important;
    }
    .text {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      letter-spacing: 0.2px;
      color: #81858e;
    }
    .textArrow {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      letter-spacing: 0.2px;
      color: #81858e;
      display: flex;
      img {
        padding-left: 20px;
      }
    }
  }
  .removeLink {
    text-decoration: none;
  }

  .disableLink {
    pointer-events: none;
  }
  .ql-editor {
    min-height: 100px !important;
  }
  .navigate {
    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      color: var(--fontcolor);
      word-break: break-word;
    }
    .lineDiv {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      height: 0.5px;
      background: #ededf0;
    }
    .spaceBtn {
      padding: 4px;
      margin-top: 10px;
      .btnDesativar {
        background: rgba(0, 35, 99, 0.1);
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 30px;
        border: none;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #002363;
        height: 45px;
        width: 100%;
      }
    }
    .spaceNav {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      transform: translateY(0px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
      .text {
        font-family: Inter;
        font-style: normal;
        font-size: 15px;
        color: #81858e;
      }
    }
    .spaceNav:hover {
      transform: translateY(2px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
    }
  }
  .editModuleClass {
    position: fixed;
    background: rgba(0, 0, 0, 0.24);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999999999999999999;
    .contentAdd {
      z-index: 99999999999999999999999999;
      position: absolute;
      padding: 30px;
      right: 0;
      top: 25vh;
      width: 303px;
      height: 295px;
      background: #ffffff;
      border: solid 1px var(--bordercolor);
      box-sizing: border-box;
      border-radius: 3px;
      .close {
        cursor: pointer;
      }
      .space {
        margin-top: 40px;
        margin-bottom: 40px;
        input {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 40px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #ededf0;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        input:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        label {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: var(--fontcolor);
        }
        .btn-salvar {
          background: var(--maincolor);
          border-radius: 30px;
          border: none;
          width: 146px;
          font-family: Inter;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 40px;
        }
      }
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--maincolor);
      }
    }
  }
  .hide {
    display: none !important;
  }
  .spaceWizzard {
    .viewAulas {
      .flexAula {
        display: flex;
        justify-content: space-between;
        .flex {
          display: flex;
          .action {
            position: relative;
            top: 8px;
          }
          .play {
            position: relative;
            top: 6px;
          }
        }
      }
      .textAula {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #c4c4c4;
        margin-left: 20px;
      }
      .timeAula {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #c4c4c4;
      }
      .actionAula {
        display: flex;
        justify-content: space-between;
        svg {
          position: relative;
          left: 24px;
          z-index: 2;
        }
        button.btn.dropdown-toggle.btn-secondary,
        button.btn.dropdown-toggle.btn-secondary:hover,
        button.btn.dropdown-toggle.btn-secondary:focus,
        button.btn.dropdown-toggle.btn-secondary:active,
        button.btn.dropdown-toggle.btn-secondary:focus-within,
        button.btn.dropdown-toggle.btn-secondary:focus-visible {
          background: rgba(255, 255, 255, 0) !important;
          border: rgba(0, 0, 0, 0) !important;
          box-shadow: none !important;
          z-index: 9;
          color: #303e3e00 !important;
        }
        a.dropdown-item {
          font-family: Inter !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          color: #333 !important;
        }
        .dropdown-item:hover,
        .dropdown-item:focus {
          color: #333 !important;
          text-decoration: none !important;
          background-color: #fff !important;
        }
      }
    }
    .blur1 {
      position: fixed;
      left: 1px;
      bottom: -60px;
    }
    .blur2 {
      position: fixed;
      right: 1px;
      top: 80px;
      transform: rotate(180deg);
    }
    .spaceInputs {
      margin-bottom: 30px;
      .textInfo {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #81858e;
        margin-top: 5px;
      }
      .btn-criar {
        background: var(--maincolor);
        border: 1px solid var(--maincolor);
        box-sizing: border-box;
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 30px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: var(--buttonTextColor);
        width: 100%;
        height: 55px;
        margin-top: 20px;
      }
      .btn-openModal {
        background: var(--maincolortrans);
        border: 2px dashed var(--maincolor);
        box-sizing: border-box;
        border-radius: 30px;
        height: 55px;
        cursor: pointer;
        .text {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: var(--maincolor);
          display: flex;
          justify-content: center;
          margin-top: 17px;
        }
      }
      .gerenciarCat {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.2px;
        color: var(--maincolor);
        position: absolute;
        right: 9em;
        cursor: pointer;
      }
      select {
        border: 1px solid var(--bordercolor);
        box-sizing: border-box;
        border-radius: 30px;
        height: 55px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #6d767e;
        overflow: hidden !important;
        resize: none !important;
      }
      input,
      textarea {
        border: 1px solid var(--bordercolor);
        box-sizing: border-box;
        border-radius: 30px;
        height: 55px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #000000;
        overflow: hidden !important;
        resize: none !important;
        background: white;
      }
      input:hover,
      select:hover,
      textarea:hover,
      input:focus,
      select:focus,
      textarea:focus {
        border: 1px solid var(--maincolor);
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #000000;
      }
      label {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--fontcolor2);
      }
    }
  }
  .spaceInputsCreate {
    display: flex;
    justify-content: flex-end;
    position: relative;
    left: 5em;
    top: -2em;
    .btn-criar {
      background: var(--maincolor);
      border: 1px solid var(--maincolor);
      box-sizing: border-box;
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      border-radius: 30px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
      width: 100%;
      height: 55px;
      margin-top: 40px;
      width: 250px;
    }
  }
  .containerWizzardInputs {
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    margin-top: 50px;
    .paddingNewModule {
      padding-left: 80px;
      padding-right: 80px;
      padding-top: 10px;
    }
    .btn-newModule {
      background: rgba(129, 133, 142, 0.03);
      border: 2px dashed #81858e;
      box-sizing: border-box;
      border-radius: 30px;
      height: 55px;
      cursor: pointer;
      .text {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #81858e;
        display: flex;
        justify-content: center;
        margin-top: 17px;
      }
    }
    .removemb {
      margin-bottom: 0px !important;
    }
    .actionModule {
      display: flex;
      justify-content: space-between;
      .titleModule {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: var(--maincolor);
      }
      svg {
        position: relative;
        left: 24px;
        z-index: 2;
      }
      button.btn.dropdown-toggle.btn-secondary,
      button.btn.dropdown-toggle.btn-secondary:hover,
      button.btn.dropdown-toggle.btn-secondary:focus,
      button.btn.dropdown-toggle.btn-secondary:active,
      button.btn.dropdown-toggle.btn-secondary:focus-within,
      button.btn.dropdown-toggle.btn-secondary:focus-visible {
        background: rgba(255, 255, 255, 0) !important;
        border: rgba(0, 0, 0, 0) !important;
        box-shadow: none !important;
        z-index: 9;
        color: #303e3e00 !important;
      }
      a.dropdown-item {
        font-family: Inter !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        color: #333 !important;
      }
      .dropdown-item:hover,
      .dropdown-item:focus {
        color: #333 !important;
        text-decoration: none !important;
        background-color: #fff !important;
      }
    }
    .lineDiv {
      background: #ededf0;
      transform: matrix(1, 0, 0, -1, 0, 0);
      height: 0.5px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .spaceModuleCard {
      width: 100%;
      background: #ffffff;
      border: 0.5px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 30px;
      padding: 32px;
      margin-bottom: 20px;
    }
  }
  .containerWizzard {
    text-align: center;
    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      align-items: center;
      color: var(--fontcolor);
    }
  }
  .containerx {
    width: 98%;
  }
}
</style>
